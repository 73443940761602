import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClientModule} from '@angular/common/http'
import { IonicSelectableModule } from 'ionic-selectable';
import { Push } from '@awesome-cordova-plugins/push/ngx';



@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
     IonicModule.forRoot(),
      AppRoutingModule,
      HttpClientModule,
      ],
  providers: [{ provide: RouteReuseStrategy, useClass: IonicRouteStrategy }, Push]
             ,
  bootstrap: [AppComponent],
})
export class AppModule {

  constructor(){
    



    
  }
}
