import { Component, OnInit, Optional } from '@angular/core';
import { Router } from '@angular/router';
import { Push, PushObject, PushOptions } from '@awesome-cordova-plugins/push/ngx';
import { App } from '@capacitor/app';
import { AlertController, IonRouterOutlet, LoadingController, Platform, ToastController } from '@ionic/angular';
import { AuthService } from './api/auth.service';
import { UserProfileService } from './api/user-profile.service';


@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})

export class AppComponent implements OnInit {


  public token_value: string;


  
  // data: any = [];

  constructor(
    public authService: AuthService,
    public userProfile: UserProfileService,
    private toastController:ToastController,
    private router :Router,
    private push: Push,
    @Optional() private routerOutlet?: IonRouterOutlet


  ) {
    


    this.token_value = localStorage.getItem('auth_token')



    console.log(window.location.pathname);
    
    if(this.token_value){
      if(window.location.pathname=='/'){
        this.router.navigate(['Home'])
      }
    }
    else{
      if(window.location.pathname=='/Home/Send-Money'){
        const queryString = window.location.search;
        localStorage.setItem('sendmoneyparams',queryString)
      }

      this.router.navigate(['/Log-In'])
    }


    
  }
  ngOnInit(): void {
    // this.userData()

// console.log(window.location.pathname)

    this.push.hasPermission()
    .then((res: any) => {

      if (res.isEnabled) {
        console.log('We have permission to send push notifications');
      } else {
        console.log('We do not have permission to send push notifications');
      }

    });

    // to initialize push notifications

    const options: PushOptions = {
    android: {},
    ios: {
        alert: 'true',
        badge: true,
        sound: 'false'
    },
    windows: {},
    browser: {
        pushServiceURL: 'http://push.api.phonegap.com/v1/push'
    }
    }

    const pushObject: PushObject = this.push.init(options);


    pushObject.on('notification').subscribe((notification: any) => {
      console.log('Received a notification', notification);
      if(notification.data.action == 'show_legder'){
        this.router.navigate[('')]
      }
    })

    pushObject.on('registration').subscribe((registration: any) => {
      console.log('Device registered', registration);
      console.log(JSON.stringify(registration));
      localStorage.setItem("device_token", registration.registrationId)
    });

    pushObject.on('error').subscribe(error => {
      console.error('Error with Push plugin', error);
    });


    
  }


  reloadCurrentRoute() {
    let currentUrl = this.router.url;
    this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
        this.router.navigate([currentUrl]);
    });
}

}
